import Vue from "vue";
import logo from "./logo.vue";
import logoWhite from "./logo-white.vue";
import homeHeaderRight from "./home-header-right-icon.svg";
import homeHeaderLeftBottom from "./home-header-left-bottom-icon.svg";
import stepsLoginIcon from "./stepsLoginIcon.svg";
import stepsSearchIcon from "./stepsSearchIcon.svg";
import searchIcon from "./searchIcon.svg";
import stepsSelectIcon from "./stepsSelectIcon.svg";
import stepsEyeIcon from "./stepsEyeIcon.svg";
import stepsReportIcon from "./stepsReportIcon.svg";
import haneyCell from "./haneyCell.svg";

import email from "./email-icon.svg";
import facebook from "./facebook-icon.svg";
import instagram from "./instagram-icon.svg";
import snapchat from "./snapchat-icon.svg";
import phone from "./phone-icon.svg";

import center from "./center.svg";
import stethoscope from "./stethoscope.svg";
import location from "./location.svg";
import phoneCenter from "./phone_center.svg";
import booking from "./booking.svg";
import calendar from "./calendar.svg";
import user from "./user.svg";
import log from "./log.svg";
import eye from "./eye.svg";
import navLago from "./nav-logo.vue";
import navToggleClose from "./nav-toggle-close.svg";
import navToggleOpen from "./nav-toggle-open.svg";
import xCircle from "./x-circle.svg";
import doneBooking from "./done-booking.svg";
import ChevronLeftIcon from "./ChevronLeftIcon.svg";
import ChevronRightIcon from "./ChevronRightIcon.svg";
import emptyDataIcon from "./empty_data_icon.svg";
import close from './close.svg';


Vue.component("logo-icon", logo);
Vue.component("logo-white-icon", logoWhite);
Vue.component("home-header-right-icon", homeHeaderRight);
Vue.component("home-header-left-bottom-icon", homeHeaderLeftBottom);
Vue.component("steps-login-icon", stepsLoginIcon);
Vue.component("steps-search-icon", stepsSearchIcon);
Vue.component("search-icon", searchIcon);
Vue.component("steps-select-icon", stepsSelectIcon);
Vue.component("steps-eye-icon", stepsEyeIcon);
Vue.component("steps-report-icon", stepsReportIcon);
Vue.component("haney-cell-icon", haneyCell);
Vue.component("email-icon", email);
Vue.component("facebook-icon", facebook);
Vue.component("instagram-icon", instagram);
Vue.component("snapchat-icon", snapchat);
Vue.component("phone-icon", phone);

Vue.component("center-icon", center);
Vue.component("stethoscope-icon", stethoscope);
Vue.component("location-icon", location);
Vue.component("phone-center-icon", phoneCenter);
Vue.component("booking-icon", booking);

Vue.component("calendar-icon", calendar);
Vue.component("user-icon", user);
Vue.component("log-icon", log);
Vue.component("eye-icon", eye);
Vue.component("nav-logo-icon", navLago);
Vue.component("nav-toggle-close", navToggleClose);
Vue.component("nav-toggle-open", navToggleOpen);
Vue.component("x-circle-icon", xCircle);
Vue.component("done-booking-icon", doneBooking);
Vue.component("chevron-left-icon", ChevronLeftIcon);
Vue.component("chevron-right-icon", ChevronRightIcon);
Vue.component("empty-data-icon", emptyDataIcon);
Vue.component('close-icon', close);
