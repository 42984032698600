import router from "@/router";

export default {
  async phoneVerification({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("auth/code/send", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("successMessage", "send_code_successfully");
          commit("setPhoneTokenId", respo.data.data.otp_id);
          commit("setOtpVerification", null);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async confirmPhoneVerification({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("auth/code/verification", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("successMessage", "verification_code_successfully");
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.code === "01_06") {
            commit("loadingFinish");

            commit("setOtpVerification", 2);
          }
        }
        throw error;
      });
  },
  async register({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("auth/register", data)
      .then((respo) => {
        if (respo.status === 200) {
          const date = new Date();
          const responseData = {
            ...respo.data.data.content,
            exp_date: date.setSeconds(
              date.getSeconds() + respo.data.data.content.expires_in
            ),
          };

          this._vm.$http.defaults.headers.common.Authorization = `Bearer ${responseData.token}`;
          this._vm.$cookies.set(
            "user-login-data",
            JSON.stringify(responseData),
            null,
            null,
            // "localhost",
            // "laeq.dev-station.com",
            "muahal.sa",
            true

            // httpOnly:true,
            // sameSite: true
          );

          const loginData = this._vm.$cookies.get("user-login-data", {
            parseJSON: true,
          });

          if (loginData) {
            router.push({
              name: "profile",
            });
          }
          setTimeout(() => {
            window.location.reload();
          }, 500);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async login({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("auth/login", data)
      .then((respo) => {
        if (respo.status === 200) {
          const date = new Date();
          const responseData = {
            ...respo.data.data.content,
            exp_date: date.setSeconds(
              date.getSeconds() + respo.data.data.content.expires_in
            ),
          };

          this._vm.$http.defaults.headers.common.Authorization = `Bearer ${responseData.token}`;
          this._vm.$cookies.set(
            "user-login-data",
            JSON.stringify(responseData),
            null,
            null,
            // "localhost",
            // 'laeq.dev-station.com',
            "muahal.sa",
            true

            // httpOnly:true,
            // sameSite: true
          );

          const loginData = this._vm.$cookies.get("user-login-data", {
            parseJSON: true,
          });

          if (loginData) {
            router.push({
              name: "centers",
            });
          }
          setTimeout(() => {
            window.location.reload();
          }, 500);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async checkLoggedIn({ commit, dispatch }) {
    // let loginData = JSON.parse(localStorage.getItem('loginData'))\
    const loginData = this.$cookies.get("user-login-data", { parseJSON: true });
    if (loginData) {
      if (this._vm.$http.defaults.headers.common.Authorization === undefined) {
        this._vm.$http.defaults.headers.common.Authorization = `Bearer ${loginData.token}`;
      }
      commit("setLoggedInuser", loginData);

      dispatch("loadUserDetail");
    }
  },
  async logout({ commit }) {
    this._vm.$cookies.remove("user-login-data");
    this._vm.$cookies.remove(
      "user-login-data",
      null,
      // 'localhost',
      // "laeq.dev-station.com",
      "muahal.sa",
      true

      // httpOnly:true,
      // sameSite: true
    );

    const loginData = this._vm.$cookies.get("user-login-data", {
      parseJSON: true,
    });
    if (!loginData) {
      this._vm.$http.defaults.headers.common.Authorization = undefined;
      router.push({
        name: "home",
      });
      window.location.reload();
    }
  },
  async forgetPassword({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("auth/password/forgot", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setPhoneTokenId", respo.data.data.token_id);
          this._vm.$cookies.set(
            "forgot-password-email",
            JSON.stringify(data),
            60 * 10,
            // null,
            // "localhost",
            // "laeq.dev-station.com"
            "muahal.sa",
            // true

            // httpOnly:true,
            // sameSite: true
          );
          router.push({
            name: "validate-email",
            params: { lang: i18n.locale },
          });

          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async resendEmailVerify({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("auth/email/verified", data)
      .then((respo) => {
        if (respo.status === 200) {
          this._vm.$cookies.set(
            "forgot-password-email",
            JSON.stringify(userEmail),
            60 * 10,
            // null,
            // "localhost",
            // "laeq.dev-station.com"
            "muahal.sa",
            // true

            // httpOnly:true,
            // sameSite: true
          );

          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async confirmCode({ commit }, data) {
    commit("loadingStart", null);

    const userEmail = this._vm.$cookies.get("forgot-password-email", {
      parseJSON: true,
    });
    if (userEmail) {
      await this._vm.$http
        .post("auth/email/verified/check", { ...data, ...userEmail })
        .then((respo) => {
          if (respo.status === 200) {
            commit("successMessage", "code_confirmed_successfully");

            router.push({
              name: "reset-password",
              params: {
                token: respo.data.data.token,
                lang: i18n.locale,
              },
            });

            commit("loadingFinish");
          }
        })
        .catch((error) => {
          commit("loadingFinish");
          throw error;
        });
    } else {
      commit("loadingFinish");

      router.push({
        name: "forgot-password",
        params: { lang: i18n.locale },
      });
    }
  },
  async resetPassword({ commit }, data) {
    commit("loadingStart", null);

    const userEmail = this._vm.$cookies.get("forgot-password-email", {
      parseJSON: true,
    });
    if (userEmail) {
      await this._vm.$http
        .post("auth/password/reset", { ...data, ...userEmail })
        .then((respo) => {
          if (respo.status === 200) {
            commit("successMessage", "change_password_successfully");
            router.push({
              name: "login",
              params: { lang: i18n.locale },
            });
            this._vm.$cookies.remove(
              "forgot-password-email",
              null,
              // 'localhost',
              // "laeq.dev-station.com",
              "muahal.sa",
              true

              // httpOnly:true,
              // sameSite: true
            );
            commit("loadingFinish");
          }
        })
        .catch((error) => {
          commit("loadingFinish");
          throw error;
        });
    } else {
      commit("loadingFinish");

      router.push({
        name: "forgot-password",
        params: { lang: i18n.locale },
      });
    }
  },
};
