// import axios from '@/plugins/axios';
import router from "@/router";

export default {
  async loadAnalysis({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get("customer/analyses")
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAnalysis", respo.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async uploadFiles({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("file/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async bookingRequest({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("customer/bookings", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async reportDownload({ commit }, id) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get(`report/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
          window.open(respo.data, "_blank");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
