import Vue from "vue";
import {
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BOverlay,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormGroup,
  BTabs,
  BTab,
  BFormFile,
  BModal,
  BSpinner,
  BFormRadioGroup,
  BFormRadio,
  BNavbar,
  BNavItem,
  BNavbarNav,
  BCollapse,
  BNavbarToggle,
  BNavbarBrand,
  BFormDatepicker,
  BFormCheckbox,
  BPagination,
  BInputGroup,
  BAlert,
} from "bootstrap-vue";
import App from "./App.vue";
import "./assets/svg/index";
import "bootstrap/dist/css/bootstrap.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import router from "./router";
import store from "./store";
import i18n from "@/libs/i18n";
import "@/libs/axios";
import "@/libs/vee-validate";
import "@/libs/vue-cookies";
import "@/libs/toastification";

Vue.component("v-select", vSelect);
// import assets styles
require("@/assets/scss/style.scss");
Vue.component("b-modal", BModal);
Vue.component("b-form-file", BFormFile);
Vue.component("b-form-group", BFormGroup);
Vue.component("b-form-input", BFormInput);
Vue.component("b-form-textarea", BFormTextarea);
Vue.component("b-form", BForm);
Vue.component("b-button", BButton);
Vue.component("b-overlay", BOverlay);
Vue.component("b-form-invalid-feedback", BFormInvalidFeedback);
Vue.component("b-row", BRow);
Vue.component("b-col", BCol);
Vue.component("b-tabs", BTabs);
Vue.component("b-tab", BTab);
Vue.component("b-spinner", BSpinner);
Vue.component("b-form-radio-group", BFormRadioGroup);
Vue.component("b-form-radio", BFormRadio);
Vue.component("b-navbar", BNavbar);
Vue.component("b-navbar-brand", BNavbarBrand);
Vue.component("b-navbar-toggle", BNavbarToggle);
Vue.component("b-collapse", BCollapse);
Vue.component("b-navbar-nav", BNavbarNav);
Vue.component("b-nav-item", BNavItem);
Vue.component("b-form-datepicker", BFormDatepicker);
Vue.component("b-form-checkbox", BFormCheckbox);
Vue.component("b-pagination", BPagination);
Vue.component("b-input-group", BInputGroup);
Vue.component("b-alert", BAlert);
import validationMessages from "vee-validate/dist/locale/ar";

// import VeeValidate, { Validator } from 'vee-validate';

// // Install the Plugin.
// Vue.use(VeeValidate);
// Validator.localize('ar', ar);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    this.$validator.localize("ar", {
      messages: {
        max_value: "يجب ان تكون القيمة اقل من او تساوي {max} %",
        max: function (n, e) {
          return (
            "الحقل " + n + " يجب ان يحتوي على " + e[0] + " حروف على الأكثر"
          );
        },
        min: function (n, e) {
          return "الحقل " + n + " يجب ان يحتوي على " + e[0] + " حروف على الأقل";
        },
        regex: "تاكد من ادخال البيانات بطريقة صحيحة",
        required: "هذا الحقل مطلوب",
        numeric: "هذا الحقل يجب ان يكون رقم",
        double: "هذا الحقل يجب ان يكون رقم",
        email: "الرجاء إدخال عنوان بريد إلكتروني صالح",
        password:
          "يجب أن يحتوي الحقل الخاص بك على الأقل على حرف كبير واحد وحرف صغير وحرف خاص واحد ورقم واحد",
        confirmed: "يجب ان تكون كلمة المرور متطابقتين",
      },
      names: {
        email: "البريد الإلكتروني",
        password: "كلمة السر",
      },
      fields: {
        password: {
          min: "كلمة السر قصيرة جداً سيتم اختراقك",
        },
      },
    });
  },
}).$mount("#app");
